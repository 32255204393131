import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Navigate } from 'react-router-dom';
import constate from 'constate';
import { ToggleFeatureToolbar } from '@spacefill/uikit/src/components/ToggleFeatureToolbar';

import { getEnvironmentVariable } from '../GetEnvVar';

const useFeatureToggling = ({ featureSettings = {} }) => {
    const enabledFeatures = getEnvironmentVariable('enabledFeatures');

    const FEATURE_TOGGLING_IS_ENABLED = getEnvironmentVariable('featureTogglingEnabled');
    const ENABLED_FEATURES = enabledFeatures ? enabledFeatures.split(',') : [];

    // get features from .env
    ENABLED_FEATURES.forEach((featureName) => {
        featureSettings[featureName] = true;
    });

    if (FEATURE_TOGGLING_IS_ENABLED) {
        const queryParams = new URLSearchParams(window.location.search);

        // Get feature flags from URL and override values from featureSettings AND set to localStorage
        // Then get remaining values from localStorage and overwrite featureSettings
        Object.entries(featureSettings).forEach(([key]) => {
            const keyValue = queryParams.get(key);

            if (keyValue === '1') {
                // activate feature
                featureSettings[key] = true;
                localStorage.setItem('toggled_feature_' + key, true);
            } else if (keyValue === '0') {
                // deactivate feature
                featureSettings[key] = false;
                localStorage.setItem('toggled_feature_' + key, false);
            } else {
                // get remaining feature flags from local storage
                const v = localStorage.getItem('toggled_feature_' + key);
                if (v !== null) {
                    featureSettings[key] = v === 'true';
                }
            }
        });
    }

    const [features, setFeatures] = useState(featureSettings);
    const toggleFeature = (featName) =>
        setFeatures((prevFeatures) => {
            const newFlagValue = !prevFeatures[featName];

            if (FEATURE_TOGGLING_IS_ENABLED) {
                localStorage.setItem('toggled_feature_' + featName, newFlagValue);
            }

            return {
                ...prevFeatures,
                [featName]: newFlagValue,
            };
        });

    return { features, toggleFeature };
};

const [FeatureTogglingProvider, useFeatureTogglingContext] = constate(useFeatureToggling);

const FeatureTogglingToolbar = () => {
    const FEATURE_TOGGLING_IS_ENABLED = getEnvironmentVariable('featureTogglingEnabled');
    const FEATURE_TOGGLING_IS_NOT_ENABLED = !FEATURE_TOGGLING_IS_ENABLED;

    const { features, toggleFeature } = useFeatureTogglingContext(); // cannot use hook in conditional block :'(
    const [toolbarIsOpen, setToolbarIsOpen] = useState(false);
    const [dropzoneEnabled, setDropzoneEnabled] = useState(false);
    if (FEATURE_TOGGLING_IS_NOT_ENABLED) {
        return null;
    }

    return (
        <div style={{ position: 'fixed', bottom: '0', zIndex: 99999, left: '50%' }}>
            <ToggleFeatureToolbar
                features={features}
                toggleFeature={toggleFeature}
                toolbarIsOpen={toolbarIsOpen}
                setToolbarIsOpen={setToolbarIsOpen}
                dropzoneEnabled={dropzoneEnabled}
                setDropzoneEnabled={setDropzoneEnabled}
            />
        </div>
    );
};

const Feature = ({ name, children, InactiveComponent = () => null }) => {
    const { features } = useFeatureTogglingContext();
    if (features === undefined) {
        console.error("FeatureTogglingProvider isn't initialized");
        return <InactiveComponent />;
    }
    return features[name] === true ? children : <InactiveComponent />;
};
Feature.propTypes = {
    name: PropTypes.string.isRequired,
    InactiveComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

const FeatureTogglingElement = ({
    featureName,
    whenFeatureIsDisabledRender,
    whenFeatureIsDisabledRedirectTo,
    children,
}) => {
    const { features } = useFeatureTogglingContext();

    if (featureName === undefined || features[featureName] === true) {
        return children;
    }
    if (whenFeatureIsDisabledRender) {
        return whenFeatureIsDisabledRender;
    }
    if (whenFeatureIsDisabledRedirectTo) {
        return <Navigate to={whenFeatureIsDisabledRedirectTo} />;
    }
    return null;
};

export { FeatureTogglingProvider, useFeatureTogglingContext, FeatureTogglingToolbar, Feature, FeatureTogglingElement };
