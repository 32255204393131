import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClose,
    faWarehouse,
    faCircleInfo,
    faCircleCheck,
    faCircleXmark,
    faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';

const ToastIconByType = {
    default: (
        <FontAwesomeIcon
            className='size-5 text-gray-500'
            icon={faWarehouse}
        />
    ),
    info: (
        <FontAwesomeIcon
            className='size-5 text-blue-500'
            icon={faCircleInfo}
        />
    ),
    success: (
        <FontAwesomeIcon
            className='size-5 text-green-500'
            icon={faCircleCheck}
        />
    ),
    error: (
        <FontAwesomeIcon
            className='size-5 text-red-500'
            icon={faCircleXmark}
        />
    ),
    warning: (
        <FontAwesomeIcon
            className='size-5 text-orange-500'
            icon={faTriangleExclamation}
        />
    ),
};

type ToastProps = {
    type?: 'default' | 'info' | 'success' | 'warning' | 'error';
    isVisible: boolean;
    text: string;
    onClose: () => void;
    dataTestid?: string;
    toastId?: string;
};

export const Toast = ({ type = 'default', text, onClose, isVisible, dataTestid = 'toast', toastId }: ToastProps) => (
    <div
        id={toastId}
        data-testid={`${dataTestid}-${type}`}
        className={clsx(
            ['w-96', 'rounded-lg', 'gap-4', 'p-4', 'text-sm', 'flex', 'items-start', 'justify-between', 'shadow-md'],
            {
                'bg-gray-50 text-gray-800': type === 'default',
                'bg-blue-50 text-blue-800': type === 'info',
                'bg-green-50 text-green-800': type === 'success',
                'bg-orange-50 text-orange-800': type === 'warning',
                'bg-red-50 text-red-800': type === 'error',
                'animate-leave': !isVisible,
                'animate-enter': isVisible,
            }
        )}
    >
        <div className='flex gap-4'>
            {ToastIconByType[type]}
            <span className='text-sm'>{text}</span>
        </div>
        <FontAwesomeIcon
            icon={faClose}
            data-testid='close-toast'
            className={clsx(['h-5', 'w-5', 'cursor-pointer'], {
                'text-gray-800': type === 'default',
                'text-blue-800': type === 'info',
                'text-green-800': type === 'success',
                'text-orange-800': type === 'warning',
                'text-red-800': type === 'error',
            })}
            onClick={onClose}
        />
    </div>
);
