const CustomerView = async () =>
    import('../../pages/customers/View').then((module) => ({
        Component: module.CustomerView,
    }));
const CustomersList = async () =>
    import('../../pages/customers/List').then((module) => ({
        Component: module.CustomersList,
    }));
const CustomerWarehouseConfigurationsEdit = async () =>
    import('../../pages/customers/customer-warehouse-configurations/Edit').then((module) => ({
        Component: module.CustomerWarehouseConfigurationsEdit,
    }));
const CustomerWarehouseConfigurationsView = async () =>
    import('../../pages/customers/customer-warehouse-configurations/View').then((module) => ({
        Component: module.CustomerWarehouseConfigurationsView,
    }));
const CustomerLogisticManagementItemsNew = async () =>
    import('../../pages/customers/logistic-management/inventory/items/New').then((module) => ({
        Component: module.CustomerLogisticManagementItemsNew,
    }));
const CustomerLogisticManagementItemsEdit = async () =>
    import('../../pages/customers/logistic-management/inventory/items/Edit').then((module) => ({
        Component: module.CustomerLogisticManagementItemsEdit,
    }));
const CustomerLogisticManagementOrdersView = async () =>
    import('../../pages/customers/logistic-management/orders/View').then((module) => ({
        Component: module.CustomerLogisticManagementOrdersView,
    }));
const CustomerLogisticManagementItemView = async () =>
    import('../../pages/customers/logistic-management/inventory/items/View').then((module) => ({
        Component: module.CustomerLogisticManagementItemView,
    }));
const CustomerCarrierView = async () =>
    import('../../pages/customers/carriers/Edit').then((module) => ({
        Component: module.CustomerCarrierEdit,
    }));

const getCustomerRouter = (_user, _features) => [
    {
        path: '/customers/',
        lazy: CustomersList,
    },
    {
        path: '/customers/:customerId/',
        lazy: CustomerView,
    },

    // Users
    {
        path: '/customers/:customerId/users/',
        lazy: CustomerView,
    },

    // Customer warehouse configurations
    {
        path: '/customers/:customerId/warehouse-configurations/',
        lazy: CustomerView,
    },
    {
        path: '/customers/:customerId/warehouse-configurations/:customerWarehouseConfigurationId/edit',
        lazy: CustomerWarehouseConfigurationsEdit,
    },
    {
        path: '/customers/:customerId/warehouse-configurations/:customerWarehouseConfigurationId/',
        lazy: CustomerWarehouseConfigurationsView,
    },

    // Logistic Management
    {
        path: '/customers/:customerId/logistic-management/inventory/items/',
        lazy: CustomerView,
    },
    {
        path: '/customers/:customerId/logistic-management/inventory/items/new',
        lazy: CustomerLogisticManagementItemsNew,
    },
    {
        path: '/customers/:customerId/logistic-management/inventory/items/:masterItemId/edit',
        lazy: CustomerLogisticManagementItemsEdit,
    },
    {
        path: '/customers/:customerId/logistic-management/inventory/items/:masterItemId/',
        lazy: CustomerLogisticManagementItemView,
    },
    {
        path: '/customers/:customerId/logistic-management/orders/:orderId/',
        lazy: CustomerLogisticManagementOrdersView,
    },
    {
        path: '/customers/:customerId/logistic-management/orders/',
        lazy: CustomerView,
    },
    {
        path: '/customers/:customerId/settings/',
        lazy: CustomerView,
    },
    {
        path: '/customers/:customerId/settings/edit',
        lazy: CustomerView,
    },
    {
        path: '/customers/:customerId/carriers/',
        lazy: CustomerView,
    },
    {
        path: '/customers/:customerId/carriers/:carrierId',
        lazy: CustomerCarrierView,
    },
    {
        path: '/customers/:customerId/permissions/',
        lazy: CustomerView,
    },
];

export { getCustomerRouter };
