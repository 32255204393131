const CarriersList = async () =>
    import('../../pages/carriers/List').then((module) => ({
        Component: module.CarriersList,
    }));
const CarrierView = async () =>
    import('../../pages/carriers/View').then((module) => ({
        Component: module.CarrierView,
    }));
const getCarrierRouter = (_user, _features) => [
    {
        path: '/carriers/',
        lazy: CarriersList,
    },
    {
        path: '/carriers/:carrierId/',
        lazy: CarrierView,
    },
];

export { getCarrierRouter };
