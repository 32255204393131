import { useState } from 'react';
import constate from 'constate';

const useThemeState = () => {
    // Set to false to update global todo_tasks counter
    const [themeShouldUpdate, setThemeShouldUpdate] = useState(false);

    return { themeShouldUpdate, setThemeShouldUpdate };
};

const [ThemeStateProvider, useThemeStateContext] = constate(useThemeState);

export { ThemeStateProvider, useThemeStateContext };
