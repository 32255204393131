import { NavigateWithParams } from '@spacefill/shared/src/utils/RouterUtils';
import React from 'react';

const WarehousesList = async () =>
    import('../../pages/warehouses/List').then((module) => ({
        Component: module.WarehousesList,
    }));
const WarehouseEdit = async () =>
    import('../../pages/warehouses/edit/Edit').then((module) => ({
        Component: module.WarehouseEdit,
    }));
const WarehouseNew = async () =>
    import('../../pages/warehouses/New').then((module) => ({
        Component: module.WarehouseNew,
    }));

const getWarehouseRouter = (_user, _features) => [
    // Warehouses list
    {
        path: '/warehouses/',
        lazy: WarehousesList,
    },

    // New warehouse
    {
        path: '/warehouses/new',
        lazy: WarehouseNew,
    },

    // Warehouses edit
    {
        path: '/warehouses/:warehouseId/edit',
        lazy: WarehouseEdit,
    },

    // Warehouses view page for now redirect to warehouse edit page
    {
        path: '/warehouses/:warehouseId/',
        element: <NavigateWithParams to={(params) => `/warehouses/${params.warehouseId}/edit`} />,
    },
];

export { getWarehouseRouter };
