import { filter, isEmpty } from 'lodash';
import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'uniqueProperty', function (propertyPath, message) {
    return this.test('unique', '', function (list) {
        const errors = [];

        list.forEach((item, index) => {
            const propertyValue = item?.propertyPath;

            if (propertyValue && filter(list, [propertyPath, propertyValue]).length > 1) {
                errors.push(
                    this.createError({
                        path: `${this.path}[${index}].${propertyPath}`,
                        message,
                    })
                );
            }
        });

        if (!isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return true;
    });
});

Yup.addMethod(Yup.mixed, 'isValidGmapAddress', function (message) {
    return this.test(
        'isValidGmapAddress',
        message,
        (value) => typeof value === 'object' && value !== null && value.formattedAddress !== undefined
    );
});

Yup.addMethod(Yup.string, 'email', function validateEmail(message) {
    return this.matches(/^[\w._%\-+]+@[\w._%-]+\.[A-Za-z]{2,8}$/i, {
        message,
        name: 'email',
        excludeEmptyString: false,
    });
});
