const CreateShipperRequestFromSalesforce = async () =>
    import('../../pages/imports/CreateShipperRequestFromSalesforce').then((module) => ({
        Component: module.CreateShipperRequestFromSalesforce,
    }));
const CreateOpportunityFromShipperRequest = async () =>
    import('../../pages/imports/CreateOpportunityFromShipperRequest').then((module) => ({
        Component: module.CreateOpportunityFromShipperRequest,
    }));

const getImportsRouter = (_user, _features) => [
    {
        path: '/imports/create-shipper-request-from-salesforce/:salesForceOpportunityId',
        lazy: CreateShipperRequestFromSalesforce,
    },
    {
        path: '/imports/create-opportunity-from-shipper-request/:shipperRequestId',
        lazy: CreateOpportunityFromShipperRequest,
    },
];

export { getImportsRouter };
