import { Fragment } from 'react';
import { clsx } from 'clsx';
import { faChevronDown, faChevronUp, faGripDotsVertical } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ToggleFeatureToolbarProps = {
    features: Record<string, boolean>;
    toggleFeature: (featureName: string) => unknown;
    toolbarIsOpen: boolean;
    setToolbarIsOpen: (toolbarIsOpen: boolean) => void;
    dropzoneEnabled: boolean;
    setDropzoneEnabled: (dropzoneEnabled: boolean) => void;
};

export function ToggleFeatureToolbar({
    features,
    toggleFeature,
    toolbarIsOpen,
    setToolbarIsOpen,
    dropzoneEnabled,
    setDropzoneEnabled,
}: ToggleFeatureToolbarProps) {
    const toggleToolbarVisibility = () => setToolbarIsOpen(!toolbarIsOpen);
    const renderListOfFeatureTogglingCheckboxes = () => (
        <ul className='mx-0 my-1 pl-1'>
            {Object.keys(features).map((featureName) => (
                <li
                    key={featureName}
                    className='mb-1'
                >
                    <label className='cursor-pointer'>
                        <input
                            type='checkbox'
                            className='mr-1'
                            name={featureName}
                            checked={features[featureName]}
                            onChange={() => toggleFeature(featureName)}
                        />
                        {featureName}
                    </label>
                </li>
            ))}
        </ul>
    );

    return (
        <Fragment>
            <div
                id='toggle-features-container'
                className='w-fit cursor-grab border-4 border-solid border-red-500 bg-yellow-300 p-2'
                draggable
                onDragStart={(e) => {
                    const element = e.target as HTMLElement;
                    e.dataTransfer.effectAllowed = 'move';
                    e.dataTransfer.dropEffect = 'move';
                    element.dataset.mouseOffsetX = String(e.clientX - element.offsetLeft);
                    element.dataset.mouseOffsetY = String(e.clientY - element.offsetTop);
                    setDropzoneEnabled(true);
                }}
                onDragEnd={(e) => {
                    const element = e.target as HTMLElement;
                    if (e.clientY > 0 && e.clientY < window.innerHeight) {
                        element.style.bottom = `${
                            window.innerHeight - e.clientY - element.offsetHeight + Number(element.dataset.mouseOffsetY)
                        }px`;
                    }
                    if (e.clientX > 0 && e.clientX < window.innerWidth) {
                        element.style.left = `${e.clientX - Number(element.dataset.mouseOffsetX)}px`;
                    }
                    setDropzoneEnabled(false);
                }}
            >
                <div className='flex select-none items-center justify-between p-1 font-medium'>
                    <div>
                        <FontAwesomeIcon icon={faGripDotsVertical} /> Toggle features
                    </div>
                    <button
                        className='ml-4 text-left'
                        onClick={toggleToolbarVisibility}
                    >
                        {toolbarIsOpen ? (
                            <>
                                Close <FontAwesomeIcon icon={faChevronDown} />
                            </>
                        ) : (
                            <>
                                Open <FontAwesomeIcon icon={faChevronUp} />
                            </>
                        )}
                    </button>
                </div>
                {toolbarIsOpen ? renderListOfFeatureTogglingCheckboxes() : null}
            </div>
            <div
                className={clsx([
                    dropzoneEnabled ? 'w-full' : 'w-0',
                    dropzoneEnabled ? 'h-full' : 'h-0',
                    dropzoneEnabled ? 'fixed' : 'absolute',
                    dropzoneEnabled ? 'z-[99999]' : 'z-[-99999]',
                ])}
                onDragOver={(ev) => ev.preventDefault()}
            />
        </Fragment>
    );
}
