import { toast as reactHotToast, Toast as ToastType } from 'react-hot-toast';

import { Toast } from './Toast';

const createToast = (text: string, type?: 'default' | 'info' | 'success' | 'warning' | 'error') =>
    reactHotToast.custom(
        (t: ToastType) => (
            <Toast
                type={type}
                text={text}
                isVisible={t.visible}
                onClose={() => reactHotToast.dismiss(t.id)}
                dataTestid='hot-toast'
            />
        ),
        {
            position: 'top-right',
        }
    );

export const toast = {
    default: (text: string) => createToast(text, 'default'),
    success: (text: string) => createToast(text, 'success'),
    info: (text: string) => createToast(text, 'info'),
    warning: (text: string) => createToast(text, 'warning'),
    error: (text: string) => createToast(text, 'error'),
};
